import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

export const PlayerProfileLink = ({
    firstPlayer = {},
    player = {},
    isAFLW,
    classes,
    trackingInfo,
    isExternal = false,
    children
}) => {
    const [playerURL, setPlayerURL] = useState('');

    useEffect(() => {
        if (player?.playerId) {
            PULSE.app.common.pulseId
                .getPlayerPulseId(player.playerId)
                .then((id) => {
                    setPlayerURL(
                        PULSE.app.common.url.getPlayerProfileURL(
                            {
                                firstName: player.firstName.toLowerCase(),
                                surname: player.surname.toLowerCase(),
                                id: id
                            },
                            isAFLW
                        )
                    );
                });
        }
    }, [player.playerId]);

    return playerURL ? (
        <a
            className={classes}
            href={playerURL}
            onClick={
                trackingInfo
                    ? () => {
                          PULSE.app.tracking.selectPlayerCardHandler(
                              firstPlayer?.player?.givenName
                                  ? firstPlayer?.player?.givenName +
                                        ' ' +
                                        firstPlayer?.player?.surname
                                  : player?.firstName + ' ' + player?.surname,
                              trackingInfo?.component_name,
                              PULSE.app.common.CONSTANTS.TRACKING.CONTENT_TYPES
                                  .PLAYER,
                              `${player?.firstName} ${player?.surname}`,
                              playerURL,
                              trackingInfo?.index,
                              trackingInfo?.count
                          );
                      }
                    : null
            }
            target={isExternal ? '_blank' : null}
            rel={isExternal ? 'noreferrer' : null}
        >
            {children}
        </a>
    ) : (
        <></>
    );
};

/* eslint-disable camelcase */
PlayerProfileLink.propTypes = {
    firstPlayer: PropTypes.object,
    player: PropTypes.shape({
        playerId: PropTypes.string.isRequired,
        firstName: PropTypes.string.isRequired,
        surname: PropTypes.string.isRequired
    }).isRequired,
    isAFLW: PropTypes.bool,
    classes: PropTypes.string,
    trackingInfo: PropTypes.shape({
        component_name: PropTypes.string,
        index: PropTypes.number,
        count: PropTypes.number
    }),
    children: PropTypes.any,
    isExternal: PropTypes.bool
};
