import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { filtersSelector } from 'store/modules/competition-nav/selectors/filters-selector';
import {
    useGetTeamsQuery,
    useGetMatchesQuery
} from 'store/modules/api/afl-api';
import { filterByCity } from '../../../../../sites/aflm/fixtures/js/utils/filter-by-city';

import { getTranslation } from '../../../utils/translations';
import { SelectMultiple } from '../../select-multiple';
import { Select } from 'common/react/components/select';
import { TeamIcon } from '../../TeamIcon';

import { UPDATE_FILTER_ITEM } from 'store/modules/competition-nav/actions';
import { UPDATE_NAV_ITEM } from 'common/store/modules/brownlow-tracker/leaderboard/actions';
import { leaderboardNavSelector } from 'common/store/modules/brownlow-tracker/leaderboard/selectors';

import { useFavouriteTeam } from 'common/react/hooks/use-favourite-team';

export const TeamFilterItem = ({
    addOnResetCallback,
    currentFilters,
    isBrownlow
}) => {
    const dispatch = useDispatch();

    const { competitionId, compSeason } = currentFilters;
    const filters = useSelector(filtersSelector);
    const nav = useSelector(leaderboardNavSelector);

    const favouriteTeam = useFavouriteTeam();

    const { data: [...teamsList] = [] } = useGetTeamsQuery(compSeason?.id, {
        skip: !compSeason?.id ? true : false
    });

    const formattedTeamsList = () => {
        return isBrownlow
            ? [
                  { id: -1, name: getTranslation('label.stats.allClubs') },
                  ...teamsList.sort((a, b) => a.name.localeCompare(b.name))
              ]
            : [...teamsList].sort((a, b) => a.name.localeCompare(b.name));
    };

    const teams = useMemo(() => {
        if (!teamsList) {
            return null;
        }

        let formattedList = formattedTeamsList();

        if (favouriteTeam) {
            const index = formattedList.findIndex((team) => {
                return (
                    PULSE.app.common.team.getTeamAbbr(team?.providerId) ===
                    favouriteTeam
                );
            });

            if (index > -1) {
                let [team] = formattedList.splice(index, 1);
                team = {
                    ...team,
                    favouriteTeam: true
                };
                formattedList.unshift(team);
            }
        }

        return formattedList;
    }, [teamsList, favouriteTeam]);

    const updateFilterItem = (filterItem, value) => {
        if (!isBrownlow) {
            dispatch(UPDATE_FILTER_ITEM.request({ filterItem, value }));
        }

        if (isBrownlow) {
            dispatch(
                UPDATE_NAV_ITEM.request({
                    navItem: 'team',
                    value: {
                        id: Number(value),
                        providerId: ''
                    }
                })
            );
        }
    };

    // if city or venue selected
    // disable non-applicable teams

    const { data: matches = [] } = useGetMatchesQuery(
        {
            pageSize: 300,
            competitionId: competitionId,
            compSeasonId: compSeason?.id,
            ...(filters.teams.length && { teamId: filters.teams }),
            ...(filters.venues.length && { venueId: filters.venues })
        },
        {
            skip: !competitionId || !compSeason?.id
        }
    );

    const filteredMatches = filters.cities.length
        ? filterByCity(matches, filters.cities)
        : null;

    let activeTeams = [];

    if (filters.venues.length || filters.cities.length) {
        if (filteredMatches?.length) {
            filteredMatches.forEach((match) => {
                activeTeams = [
                    ...activeTeams,
                    match.home?.team?.id,
                    match.away?.team?.id
                ];
            });
        } else if (matches.length) {
            matches.forEach((match) => {
                activeTeams = [
                    ...activeTeams,
                    match.home?.team?.id,
                    match.away?.team?.id
                ];
            });
        }
    }

    if (isBrownlow && teams) {
        return (
            <Select
                items={teams}
                itemToString={(team) => team?.name}
                initialSelectedItem={
                    teams.length && nav.team.id
                        ? teams.find((t) => t.id === nav.team.id)
                        : ''
                }
                activeItem={
                    teams.length && nav.team.id
                        ? teams.find((s) => s.id === nav.team.id)
                        : ''
                }
                onSelectedItemChange={({ selectedItem }) => {
                    updateFilterItem('teams', selectedItem.id);
                }}
                isReadyToRender={() => teams?.length}
                label={getTranslation('label.filters.club')}
                className="select--rounded"
            />
        );
    }

    return (
        <SelectMultiple
            items={teams}
            getInitialSelectedItems={() => {
                return teams?.filter((t) => filters.teams.includes(t.id));
            }}
            label={getTranslation('label.fixtures.filters.team')}
            icon="shield"
            itemToString={(team) => team.name}
            onSelectedItemsChange={(team) => {
                updateFilterItem(
                    'teams',
                    team.map((t) => t.id)
                );
            }}
            addOnResetCallback={addOnResetCallback}
            getItemText={(item) => item.name}
            getItemLabel={(item) => (
                <span
                    style={{
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'space-between',
                        lineHeight: 1,
                        width: '100%'
                    }}
                    className={`${
                        item.favouriteTeam
                            ? 'select-multiple__favourite-team'
                            : ''
                    }`}
                >
                    <span style={{ marginRight: '1rem' }}>{item.name}</span>
                    <TeamIcon
                        providerId={item.providerId}
                        className="icon"
                        style={{ width: '3.6rem', height: '3.6rem' }}
                    />
                </span>
            )}
            getItemDisabled={(item) =>
                activeTeams.length && !activeTeams.includes(item.id)
            }
        />
    );
};

TeamFilterItem.propTypes = {
    addOnResetCallback: PropTypes.func,
    currentFilters: PropTypes.object,
    isBrownlow: PropTypes.bool
};
