import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { Select } from '../../select';
import { getTranslation } from '../../../utils/translations';
import { navSelector } from 'store/modules/competition-nav/selectors/nav-selector';
import { UPDATE_NAV_ITEM } from 'store/modules/competition-nav/actions';

const DEFAULT_COMP_ORDER = window.DEFAULT_COMP_ORDER
    ? window.DEFAULT_COMP_ORDER.split(',')
    : [
          PULSE.app.common.CONSTANTS.PROVIDER_IDS.WOMENS,
          PULSE.app.common.CONSTANTS.PROVIDER_IDS.PRE_SEASON,
          PULSE.app.common.CONSTANTS.PROVIDER_IDS.PREMIERSHIP,
          PULSE.app.common.CONSTANTS.PROVIDER_IDS.VFL_PREMIERSHIP,
          PULSE.app.common.CONSTANTS.PROVIDER_IDS.VFL_WOMENS,
          PULSE.app.common.CONSTANTS.PROVIDER_IDS.WAFL_PREMIERSHIP,
          PULSE.app.common.CONSTANTS.PROVIDER_IDS.SANFL_PREMIERSHIP,
          PULSE.app.common.CONSTANTS.PROVIDER_IDS.U18_BOYS_CHAMPIONSHIPS,
          PULSE.app.common.CONSTANTS.PROVIDER_IDS.U18_GIRLS_CHAMPIONSHIPS
      ];

export const CompNavItem = ({
    competitions,
    allowListOrder = DEFAULT_COMP_ORDER
}) => {
    const dispatch = useDispatch();
    const nav = useSelector(navSelector);

    if (!competitions || nav.competition.id === '') {
        return '';
    }

    let competitionList = '';

    // Filter the competitions list to only show the competitions above
    const getCompetitionList = (
        data,
        providerIdsToInclude = allowListOrder
    ) => {
        /**
         * Filters the list of competitions to include only those which appear
         * in the list of competition IDs provided.
         *
         * @param { any[] } comps The list of competition data.
         * @param { string[] } compProviderIds A list of `compProviderIds` in
         *    the order we want to display them in the filter.
         * @returns { any[] } The list of competitions included in given list of
         *    `compProviderIds`.
         */
        const filterOutExcludedComps = (comps, compProviderIds) =>
            comps.filter((comp) => compProviderIds.includes(comp.providerId));

        // /**
        //  * Sorts the list of competitions into the order of the list of
        //  * competition IDs provided.
        //  *
        //  * @param { any[] } comps The list of competition data.
        //  * @param { string[] } compProviderIds A list of `compProviderIds` in
        //  *    the order we want to display them in the filter.
        //  * @returns { any[] } The list of competitions in order according to the
        //  *    given list of `compProviderIds`.
        //  */
        const sortAccordingToConfig = (comps, compProviderIds) =>
            compProviderIds
                .map((compProviderId) =>
                    comps.find((comp) => comp.providerId === compProviderId)
                )
                .filter((comp) => typeof comp !== 'undefined');

        const filtered = filterOutExcludedComps(data, providerIdsToInclude);
        const sorted = sortAccordingToConfig(filtered, providerIdsToInclude);

        competitionList = sorted;
    };

    getCompetitionList(competitions);

    return (
        <div className="stats-leaders-nav__select competition-nav__competition-select">
            <Select
                items={competitionList}
                itemToString={(competition) => competition.name}
                initialSelectedItem={competitions.find(
                    (c) => c.id === Number(nav.competition.id)
                )}
                showCompLogo={true}
                activeItem={competitionList.find(
                    (c) => c.id === Number(nav.competition.id)
                )}
                isReadyToRender={() =>
                    competitionList?.length && Number(nav.competition.id)
                }
                label={getTranslation('label.filters.competition')}
                onSelectedItemChange={({ selectedItem }) => {
                    dispatch(
                        UPDATE_NAV_ITEM.request({
                            navItem: 'competition',
                            value: {
                                id: selectedItem.id,
                                providerId: selectedItem.providerId
                            }
                        })
                    );
                }}
            />
        </div>
    );
};

CompNavItem.propTypes = {
    competitions: PropTypes.array,
    allowListOrder: PropTypes.array
};
