/* eslint-disable id-length */
/* eslint-disable react/prop-types */
/* eslint-disable max-lines */
/**
 * Use a class component to get access to lifecycle methods, otherwise use a functional component.
 * Should only contain rendering logic; business logic should be contained to Redux Sagas.
 */

import React from 'react';
import DefaultTable from 'custom-table/js/components/DefaultTable';

class LadderTable extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            favouriteTeam: false,
            data: []
        };

        this.setFavouriteTeam = this.setFavouriteTeam.bind(this);
    }

    setFavouriteTeam(eventProps) {
        const favTeam =
            eventProps?.detail ??
            PULSE.core.localStorage.getStorage('favouriteTeam', false);
        this.setState({
            favouriteTeam: favTeam
        });
    }

    componentDidMount() {
        this.setFavouriteTeam();
        document.addEventListener(
            PULSE.app.common.CONSTANTS.EVENTS.AFLID.FAVOURITE_SET,
            this.setFavouriteTeam
        );

        /*
            ideally, this can eventually be passed into DefaultCustomTable as a prop.
            then we don't need to have componentDidMount or call this.props.addCustomTable
            in extended versions of the table.
            We could potentially simplify this into a functional component rather than a class.
        */
        const tableConfig = {
            tableName: 'ladder',
            groups: [
                {
                    name: 'PersistentGroup',
                    label: 'Persistent Group',
                    isPersistent: true,
                    fields: [
                        {
                            fieldName: 'position',
                            description: 'label.position.description',
                            fieldLabel: 'label.ladder.pos',
                            fieldLabelShort: 'label.ladder.pos.short',
                            sortFunction: 'positionSort',
                            tableHeadCustomClass:
                                'custom-table__header-cell--position',
                            customCellRenderer: 'PositionCellRenderer',
                            apiMap: 0,
                            displayByDefault: true,
                            isPersistent: true,
                            selector: {
                                position: 'position',
                                positionChange: 'positionChange'
                            },
                            parentSelector: 'ladders.entries'
                        },
                        {
                            fieldName: 'clubBadge',
                            description: 'label.clubBadge.description',
                            fieldLabel: '',
                            fieldLabelShort: '',
                            sortFunction: 'teamNameSort',
                            tableHeadCustomClass:
                                'custom-table__header-cell--badge',
                            customCellRenderer: 'LadderClubBadgeCellRenderer',
                            apiMap: 0,
                            noSort: true,
                            displayByDefault: true,
                            isPersistent: true,
                            selector: {
                                abbreviation: 'team.abbreviation',
                                teamName: 'team.name',
                                providerId: 'team.providerId'
                            },
                            parentSelector: 'ladders.entries'
                        },
                        {
                            fieldName: 'club',
                            description: 'label.club.description',
                            fieldLabel: 'label.ladder.club',
                            fieldLabelShort: 'label.ladder.club',
                            sortFunction: 'teamNameSort',
                            tableHeadCustomClass:
                                'custom-table__header-cell--club',
                            customCellRenderer: 'LadderClubCellRenderer',
                            apiMap: 0,
                            displayByDefault: true,
                            isPersistent: true,
                            selector: {
                                abbreviation: 'team.abbreviation',
                                teamName: 'team.name'
                            },
                            parentSelector: 'ladders.entries'
                        }
                    ]
                },
                {
                    name: 'winLoss',
                    label: 'label.winLoss',
                    fields: [
                        {
                            fieldName: 'played',
                            description: 'label.played.description',
                            fieldLabel: 'label.ladder.played',
                            fieldLabelShort: 'label.ladder.played.short',
                            sortFunction: 'numberCompare',
                            apiMap: 0,
                            displayByDefault: true,
                            selector: 'thisSeasonRecord.winLossRecord.played',
                            parentSelector: 'ladders.entries'
                        },
                        {
                            fieldName: 'gamesWon',
                            description: 'label.won.description',
                            fieldLabel: 'label.ladder.won',
                            fieldLabelShort: 'label.ladder.won.short',
                            sortFunction: 'numberCompare',
                            apiMap: 0,
                            displayByDefault: false,
                            selector: 'thisSeasonRecord.winLossRecord.wins',
                            parentSelector: 'ladders.entries'
                        },
                        {
                            fieldName: 'gamesLost',
                            description: 'label.gamesLost.description',
                            fieldLabel: 'label.ladder.lost',
                            fieldLabelShort: 'label.ladder.lost.short',
                            sortFunction: 'numberCompare',
                            apiMap: 0,
                            displayByDefault: false,
                            selector: 'thisSeasonRecord.winLossRecord.losses',
                            parentSelector: 'ladders.entries'
                        },
                        {
                            fieldName: 'winRatio',
                            description: 'label.winRatio.description',
                            fieldLabel: 'label.ladder.winRatio',
                            fieldLabelShort: 'label.ladder.winRatio.short',
                            sortFunction: 'numberCompare',
                            visibiltyConditionFunc: 'shouldShowWinLoss',
                            apiMap: 0,
                            displayByDefault: true,
                            selector: 'thisSeasonRecord.winRatio',
                            parentSelector: 'ladders.entries'
                        },
                        {
                            fieldName: 'gamesDrawn',
                            description: 'label.gamesDrawn.description',
                            fieldLabel: 'label.ladder.drawn',
                            fieldLabelShort: 'label.ladder.drawn.short',
                            sortFunction: 'numberCompare',
                            apiMap: 0,
                            displayByDefault: false,
                            selector: 'thisSeasonRecord.winLossRecord.draws',
                            parentSelector: 'ladders.entries'
                        },
                        {
                            fieldName: 'homeRecord',
                            description: 'label.homeRecord.description',
                            fieldLabel: 'label.homeRecord',
                            fieldLabelShort: 'label.homeRecord.short',
                            sortFunction: 'customNumberCompare',
                            apiMap: 0,
                            displayByDefault: false,
                            customCellRenderer: 'MultipleTableCellRenderer',
                            customTableHeadRenderer:
                                'MultipleTableHeadRenderer',
                            tableHeadLabels: {
                                played: 'label.ladder.played.short',
                                wins: 'label.ladder.won.short',
                                losses: 'label.ladder.lost.short',
                                draws: 'label.ladder.drawn.short'
                            },
                            selector: {
                                played: 'homeRecord.played',
                                wins: 'homeRecord.wins',
                                losses: 'homeRecord.losses',
                                draws: 'homeRecord.draws'
                            },
                            parentSelector: 'ladders.entries'
                        },
                        {
                            fieldName: 'interstateRecord',
                            description: 'label.interstateRecord.description',
                            fieldLabel: 'label.interstateRecord',
                            fieldLabelShort: 'label.interstateRecord.short',
                            sortFunction: 'customNumberCompare',
                            apiMap: 0,
                            displayByDefault: false,
                            customCellRenderer: 'MultipleTableCellRenderer',
                            customTableHeadRenderer:
                                'MultipleTableHeadRenderer',
                            tableHeadLabels: {
                                played: 'label.ladder.played.short',
                                wins: 'label.ladder.won.short',
                                losses: 'label.ladder.lost.short',
                                draws: 'label.ladder.drawn.short'
                            },
                            selector: {
                                played: 'interstateRecord.played',
                                wins: 'interstateRecord.wins',
                                losses: 'interstateRecord.losses',
                                draws: 'interstateRecord.draws'
                            },
                            parentSelector: 'ladders.entries'
                        },
                        {
                            fieldName: 'dayRecord',
                            description: 'label.dayRecord.description',
                            fieldLabel: 'label.dayRecord',
                            fieldLabelShort: 'label.dayRecord.short',
                            sortFunction: 'customNumberCompare',
                            apiMap: 0,
                            displayByDefault: false,
                            customCellRenderer: 'MultipleTableCellRenderer',
                            customTableHeadRenderer:
                                'MultipleTableHeadRenderer',
                            tableHeadLabels: {
                                played: 'label.ladder.played.short',
                                wins: 'label.ladder.won.short',
                                losses: 'label.ladder.lost.short',
                                draws: 'label.ladder.drawn.short'
                            },
                            selector: {
                                played: 'dayRecord.played',
                                wins: 'dayRecord.wins',
                                losses: 'dayRecord.losses',
                                draws: 'dayRecord.draws'
                            },
                            parentSelector: 'ladders.entries'
                        },
                        {
                            fieldName: 'nightRecord',
                            description: 'label.nightRecord.description',
                            fieldLabel: 'label.nightRecord',
                            fieldLabelShort: 'label.nightRecord.short',
                            sortFunction: 'customNumberCompare',
                            customCellRenderer: 'MultipleTableCellRenderer',
                            customTableHeadRenderer:
                                'MultipleTableHeadRenderer',
                            tableHeadLabels: {
                                played: 'label.ladder.played.short',
                                wins: 'label.ladder.won.short',
                                losses: 'label.ladder.lost.short',
                                draws: 'label.ladder.drawn.short'
                            },
                            apiMap: 0,
                            selector: {
                                played: 'nightRecord.played',
                                wins: 'nightRecord.wins',
                                losses: 'nightRecord.losses',
                                draws: 'nightRecord.draws'
                            },
                            parentSelector: 'ladders.entries'
                        }
                    ]
                },
                {
                    name: 'quartersWon',
                    label: 'label.quartersWon',
                    fields: [
                        {
                            fieldName: 'firstQuarter',
                            description: 'label.firstQuarter.description',
                            fieldLabel: 'label.firstQuarter',
                            fieldLabelShort: 'label.firstQuarter.short',
                            sortFunction: 'numberCompare',
                            apiMap: 0,
                            displayByDefault: false,
                            selector: 'quartersWon.winQ1',
                            parentSelector: 'ladders.entries'
                        },
                        {
                            fieldName: 'secondQuarter',
                            description: 'label.secondQuarter.description',
                            fieldLabel: 'label.secondQuarter',
                            fieldLabelShort: 'label.secondQuarter.short',
                            sortFunction: 'numberCompare',
                            apiMap: 0,
                            displayByDefault: false,
                            selector: 'quartersWon.winQ2',
                            parentSelector: 'ladders.entries'
                        },
                        {
                            fieldName: 'thirdQuarter',
                            description: 'label.thirdQuarter.description',
                            fieldLabel: 'label.thirdQuarter',
                            fieldLabelShort: 'label.thirdQuarter.short',
                            sortFunction: 'numberCompare',
                            apiMap: 0,
                            displayByDefault: false,
                            selector: 'quartersWon.winQ3',
                            parentSelector: 'ladders.entries'
                        },
                        {
                            fieldName: 'fourthQuarter',
                            description: 'label.fourthQuarter.description',
                            fieldLabel: 'label.fourthQuarter',
                            fieldLabelShort: 'label.fourthQuarter.short',
                            sortFunction: 'numberCompare',
                            apiMap: 0,
                            displayByDefault: false,
                            selector: 'quartersWon.winQ4',
                            parentSelector: 'ladders.entries'
                        },
                        {
                            fieldName: 'quartersWon',
                            description: 'label.quartersWon.description',
                            fieldLabel: 'label.quartersWon',
                            fieldLabelShort: 'label.quartersWon.short',
                            sortFunction: 'numberCompare',
                            apiMap: 0,
                            displayByDefault: false,
                            selector: 'quartersWon.total',
                            parentSelector: 'ladders.entries'
                        }
                    ]
                },
                {
                    name: 'attackDefence',
                    label: 'label.attackDefence',
                    fields: [
                        {
                            fieldName: 'percentage',
                            description: 'label.percentage.description',
                            fieldLabel: 'label.percentage',
                            fieldLabelShort: 'label.percentage.short',
                            sortFunction: 'numberCompare',
                            apiMap: 0,
                            displayByDefault: false,
                            selector: 'thisSeasonRecord.percentage',
                            parentSelector: 'ladders.entries'
                        },
                        {
                            fieldName: 'pointsFor',
                            description: 'label.pointsFor.description',
                            fieldLabel: 'label.ladder.pointsFor',
                            fieldLabelShort: 'label.ladder.pointsFor.short',
                            sortFunction: 'numberCompare',
                            apiMap: 0,
                            displayByDefault: false,
                            selector: 'pointsFor',
                            parentSelector: 'ladders.entries'
                        },
                        {
                            fieldName: 'pointsAgainst',
                            description: 'label.pointsAgainst.description',
                            fieldLabel: 'label.ladder.pointsAgainst',
                            fieldLabelShort: 'label.ladder.pointsAgainst.short',
                            sortFunction: 'numberCompare',
                            apiMap: 0,
                            displayByDefault: false,
                            selector: 'pointsAgainst',
                            parentSelector: 'ladders.entries'
                        },
                        {
                            fieldName: 'highestPointsScored',
                            description:
                                'label.highestPointsScored.description',
                            fieldLabel: 'label.highestPointsScored',
                            fieldLabelShort: 'label.highestPointsScored.short',
                            sortFunction: 'numberCompare',
                            apiMap: 0,
                            displayByDefault: false,
                            selector: 'maxScore',
                            parentSelector: 'ladders.entries'
                        },
                        {
                            fieldName: 'lowestPointsScored',
                            description: 'label.lowestPointsScored.description',
                            fieldLabel: 'label.lowestPointsScored',
                            fieldLabelShort: 'label.lowestPointsScored.short',
                            sortFunction: 'numberCompare',
                            apiMap: 0,
                            displayByDefault: false,
                            selector: 'minScore',
                            parentSelector: 'ladders.entries'
                        },
                        {
                            fieldName: 'avgWinMargin',
                            description: 'label.avgWinMargin.description',
                            fieldLabel: 'label.avgWinMargin',
                            fieldLabelShort: 'label.avgWinMargin.short',
                            sortFunction: 'numberCompare',
                            apiMap: 0,
                            displayByDefault: false,
                            selector: 'avgWinMargin',
                            parentSelector: 'ladders.entries'
                        },
                        {
                            fieldName: 'avgLossMargin',
                            description: 'label.avgLossMargin.description',
                            fieldLabel: 'label.avgLossMargin',
                            fieldLabelShort: 'label.avgLossMargin.short',
                            sortFunction: 'numberCompare',
                            apiMap: 0,
                            displayByDefault: false,
                            selector: 'avgLossMargin',
                            parentSelector: 'ladders.entries'
                        },
                        {
                            fieldName: 'playersUsed',
                            description: 'label.playersUsed.description',
                            fieldLabel: 'label.playersUsed',
                            fieldLabelShort: 'label.playersUsed.short',
                            sortFunction: 'numberCompare',
                            apiMap: 0,
                            displayByDefault: false,
                            selector: 'playersUsed',
                            parentSelector: 'ladders.entries'
                        }
                    ]
                },
                {
                    name: 'Fixture',
                    label: 'Fixture',
                    fields: [
                        {
                            fieldName: 'points',
                            description: 'label.points.description',
                            fieldLabel: 'label.ladder.points',
                            fieldLabelShort: 'label.ladder.points.short',
                            sortFunction: 'numberCompare',
                            apiMap: 0,
                            displayByDefault: true,
                            selector: 'thisSeasonRecord.aggregatePoints',
                            parentSelector: 'ladders.entries'
                        },
                        {
                            fieldName: 'last5Games',
                            description: 'label.last5Games.description',
                            fieldLabel: 'label.last5Games',
                            fieldLabelShort: 'label.last5Games.short',
                            tableHeadCustomClass:
                                'custom-table__header-cell--form',
                            sortFunction: 'formCompare',
                            apiMap: 0,
                            displayByDefault: false,
                            customCellRenderer: 'LadderFormCellRenderer',
                            selector: {
                                form: 'form',
                                teamId: 'team.id'
                            },
                            parentSelector: 'ladders.entries'
                        },
                        {
                            fieldName: 'nextGame',
                            description: 'label.nextGame.description',
                            fieldLabel: 'label.nextGame',
                            fieldLabelShort: 'label.nextGame.short',
                            tableHeadCustomClass:
                                'custom-table__header-cell--next-prev',
                            customCellRenderer: 'LadderNextPrevCellRenderer',
                            sortFunction: 'numberCompare',
                            noSort: true,
                            apiMap: 0,
                            displayByDefault: false,
                            selector: {
                                abbreviation: 'nextOpponent.abbreviation',
                                providerId: 'nextOpponent.providerId'
                            },
                            parentSelector: 'ladders.entries'
                        },
                        {
                            fieldName: 'lastGame',
                            description: 'label.lastGame.description',
                            fieldLabel: 'label.lastGame',
                            fieldLabelShort: 'label.lastGame.short',
                            tableHeadCustomClass:
                                'custom-table__header-cell--next-prev',
                            customCellRenderer: 'LadderNextPrevCellRenderer',
                            noSort: true,
                            apiMap: 0,
                            displayByDefault: false,
                            selector: {
                                abbreviation: 'lastOpponent.abbreviation',
                                providerId: 'lastOpponent.providerId'
                            },
                            parentSelector: 'ladders.entries'
                        }
                    ]
                }
            ],
            infoText: 'Your custom ladder',
            jsonApis: [
                {
                    api: this.getLadderEndpoint(),
                    token: false
                }
            ],
            headers: {
                [PULSE.app.accountHeader.label]: PULSE.app.accountHeader.value
            },
            showTableTitle: true,
            tableTitle: 'ladders.0.conference',
            sortBy: {
                fieldName: 'position',
                direction: 'ASC'
            },
            rowIdentifier: {
                parentSelector: 'ladders.0.entries',
                selector: 'team.providerId',
                apiMap: 0
            }
        };

        this.props.addCustomTable(tableConfig);
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            this.props.sortedTableData !== prevProps.sortedTableData ||
            this.state.favouriteTeam !== prevState.favouriteTeam ||
            this.props.customTable?.sortBy.fieldName !==
                prevProps.customTable?.sortBy.fieldName ||
            this.props.customTable?.sortBy.direction !==
                prevProps.customTable?.sortBy.direction
        ) {
            this.enrichTableDataWithFavTeam();
        }
    }

    componentWillUnmount() {
        document.removeEventListener(
            PULSE.app.common.CONSTANTS.EVENTS.AFLID.FAVOURITE_SET,
            this.setFavouriteTeam
        );
    }

    enrichTableDataWithFavTeam() {
        const enrichedData = this.props.sortedTableData.map((row) => {
            if (
                row.club?.abbreviation?.toLowerCase() ===
                    this.state.favouriteTeam &&
                (this.props?.customTable?.filterOptions?.competition ===
                    window.PULSE.envPaths.competitions.AFL ||
                    this.props?.customTable?.filterOptions?.competition ===
                        window.PULSE.envPaths.competitions.AFLW)
            ) {
                return { ...row, isFavouriteTeam: true };
            }

            return row;
        });
        this.setState({ data: enrichedData });
    }

    getLadderEndpoint = () => {
        const roundId = this.props.data.roundId;
        const compSeason = this.props.data.compseasonId;

        return `${PULSE.app.environment.api}compseasons/${compSeason}/ladders?roundId=${roundId}`;
    };

    render() {
        return (
            <DefaultTable {...this.props} sortedTableData={this.state.data} />
        );
    }
}

export default LadderTable;
