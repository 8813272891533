import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { ErrorBoundary } from 'react-error-boundary';
import { isMobile } from 'react-device-detect';
import { useSelector } from 'react-redux';

import { BroadcasterPromo } from './broadcaster-promo/BroadcasterPromo';
import { getTranslation } from 'common/react/utils/translations';
import { FixturesDateHeader } from './FixturesDateHeader';
import { FixturesRoundHeader } from './FixturesRoundHeader';
import { Fixture } from './Fixture';
import { FilteredByesGroup } from './FilteredByesGroup';
import { filtersSelector } from 'common/store/modules/competition-nav/selectors';

const { PROVIDER_IDS } = PULSE.app.common.CONSTANTS;

const DIGIT_ONLY_REGEX = /^[0-9]*$/;

export const FixturesItem = ({
    item,
    specialRound,
    broadcastGuideUrl,
    disableRounds,
    broadcasterPromo,
    firstLiveOrUpcomingFixture,
    competitionPid,
    compseasonId,
    roundNumber
}) => {
    const filters = useSelector(filtersSelector);

    const filteredTeams = useMemo(() => {
        const teamIds = filters.teams.map((id) => parseInt(id));
        const byes = item.byes || (item.round ? item.round.byes : []);
        return byes.filter((bye) => teamIds.includes(bye.id));
    }, [item, filters.teams]);

    const itemType = useMemo(() => {
        if (typeof item === 'string') {
            if (item.match(DIGIT_ONLY_REGEX) || item === 'TBCPC') {
                return 'date';
            }
            return 'round';
        }
        if (
            typeof item === 'object' &&
            'isBye' in item &&
            item.isBye === true
        ) {
            return 'bye';
        }

        return 'match';
    }, [item]);

    const reportLabel = PULSE.app.common.url.urlify(
        getTranslation('label.mc.reportTab')
    );

    const mcLink = [
        PROVIDER_IDS.PRE_SEASON,
        PROVIDER_IDS.INDIGENOUS_ALL_STARS,
        PROVIDER_IDS.PREMIERSHIP,
        PROVIDER_IDS.VFL_PREMIERSHIP,
        PROVIDER_IDS.VFL_WOMENS,
        PROVIDER_IDS.WAFL_PREMIERSHIP,
        PROVIDER_IDS.WOMENS,
        PROVIDER_IDS.SANFL_PREMIERSHIP,
        PROVIDER_IDS.U18_BOYS_CHAMPIONSHIPS,
        PROVIDER_IDS.U18_GIRLS_CHAMPIONSHIPS
    ].includes(competitionPid);

    switch (itemType) {
        case 'date':
            return <FixturesDateHeader dateKey={item} />;
        case 'round':
            return disableRounds ? (
                <FixturesRoundHeader roundLabel={item} />
            ) : null;
        case 'bye':
            return (
                <FilteredByesGroup
                    teams={filteredTeams}
                    roundName={item.name}
                />
            );
        default:
            return (
                <ErrorBoundary key={item.id} fallback={<></>}>
                    <>
                        <Fixture
                            mcLink={mcLink}
                            match={item}
                            specialRound={specialRound}
                            reportLabel={reportLabel}
                            broadcastGuideUrl={broadcastGuideUrl}
                            compseasonId={compseasonId}
                            roundNumber={roundNumber}
                        />

                        {broadcasterPromo &&
                            isMobile &&
                            item.id === firstLiveOrUpcomingFixture && (
                                <BroadcasterPromo promo={broadcasterPromo} />
                            )}
                    </>
                </ErrorBoundary>
            );
    }
};

FixturesItem.propTypes = {
    item: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    specialRound: PropTypes.string,
    broadcastGuideUrl: PropTypes.string,
    round: PropTypes.object,
    disableRounds: PropTypes.bool,
    broadcasterPromo: PropTypes.object,
    firstLiveOrUpcomingFixture: PropTypes.number,
    competitionPid: PropTypes.string.isRequired,
    compseasonId: PropTypes.number.isRequired,
    roundNumber: PropTypes.number.isRequired
};
